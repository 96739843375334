import { temperature } from 'chroma-js';
import i18n from './i18n';
import { createEnum } from './utility-functions/enum';

export const maxSiteWidth = '77rem';

export const CENTER_CONTENT_STYLE = Object.freeze({
	width: '65rem',
	height: '100%',
});

// TODO: Move i18n.t to their respective areas of usage in each component. Then replace for example "i18n.t('constants.overview')" with just 'constants.overview'.
// Basically move the translation from this file to each file and avoid using i18n.t.
// So a component will instead use for example t(PAGES.overview.label) as their tag.
// Currently this is solved in Noyify.js in the updateTranslations function with "window.location.reload();". This is a hack and is not a clean solution.

export const PAGES = Object.seal({
	overview: { id: 'overview', label: i18n.t('constants.overview') },
	properties: { id: 'properties', label: i18n.t('constants.properties') },
	map: { id: 'map', label: i18n.t('constants.map') },
	alarms: { id: 'alarms', label: i18n.t('constants.alarms') },
	deviations: { id: 'deviations', label: i18n.t('constants.deviations') },
	analysis: { id: 'analysis', label: i18n.t('constants.analysis') },
	reports: { id: 'reports', label: i18n.t('constants.reports') },
	actions: { id: 'actions', label: i18n.t('constants.actions') },
	issues: { id: 'issues', label: i18n.t('constants.errorReporting') },
	assignments: { id: 'assignments', label: i18n.t('constants.caseManagement') },
	automation: { id: 'automation', label: i18n.t('constants.automation') },
	administration: { id: 'administration', label: i18n.t('constants.administration') },
	charts: { id: 'charts', label: i18n.t('constants.charts') },
});

export const PROPERTY_TABS = Object.seal({
	meters: { id: 'meters', label: i18n.t('constants.meters') },
	alarmHistory: { id: 'alarm-history', label: i18n.t('constants.alarmHistory') },
	actions: { id: 'actions', label: i18n.t('constants.actions') },
	deviations: { id: 'deviations', label: i18n.t('constants.deviations') },
	threeSixty: { id: 'three-sixty', label: i18n.t('constants.threeSixty') },
	externalControlPanels: { id: 'externalControlPanels', label: i18n.t('constants.externalControlPanels') },
	cos: { id: 'controlObjects', label: i18n.t('constants.cos') },
	opcard: { id: 'opcard', label: i18n.t('constants.opcard') },
	documents: { id: 'documents', label: i18n.t('constants.documents') },
	curve: { id: 'curve', label: i18n.t('constants.curve') },
	indoorSensor: { id: 'indoorSensor', label: i18n.t('constants.energyPerformance') },
	energyPerformance: { id: 'energyPerformance', label: i18n.t('constants.indoorsensor') },
	results: { id: 'results', label: i18n.t('constants.results') },
	aiControl: { id: 'aiControl', label: i18n.t('generic.aiControl') },
});

export const AI_TABS = Object.seal({
	results: { id: 'results', label: i18n.t('constants.results') },
	/* curve: {id: 'curve', label: i18n.t('constants.curve')}, */
	indoorSensor: { id: 'indoorSensor', label: i18n.t('constants.indoorsensor') },
	//energyPerformance: {id: 'energyPerformance', label: i18n.t('constants.energyPerformance')},
	aiControl: { id: 'aiControl', label: i18n.t('generic.aiControl') },
});

export const COMMUNICATION_SENSOR_TYPES = Object.freeze({
	sensor: { id: 'sensor', label: i18n.t('automations.sensor') },
	modbus: { id: 'modbus', label: i18n.t('automations.modbus') },
	outdoorTemp: { id: 'outdoortemp', label: i18n.t('automations.outdoorTemp') },
	electricity: { id: 'electricity', label: i18n.t('automations.electricity') },
	indoorTemp: { id: 'indoortemp', label: i18n.t('automations.indoorTemp') },
	supplyTemperature: { id: 'supplytemp', label: i18n.t('automations.supplyTemp') },
});

export const ADMIN_TABS = Object.seal({
	personal: { id: 'user', label: i18n.t('constants.personal') },
	users: { id: 'users', label: i18n.t('constants.users') },
	sensorSubscriptions: { id: 'sensorsubscriptions', label: i18n.t('constants.sensorSubscriptions') },
	sensors: { id: 'sensors', label: i18n.t('constants.sensors') },
	sensorGroups: { id: 'sensorgroups', label: i18n.t('constants.sensorGroups') },
	properties: { id: 'properties', label: i18n.t('constants.properties') },
	controlObjects: { id: 'controlObjects', label: i18n.t('constants.controlObjects') },
	threeSixtyModels: { id: 'threeSixtyModels', label: i18n.t('constants.threeSixtyModels') },
	cameras: { id: 'cameras', label: i18n.t('constants.cameras') },
	externalControlPanels: { id: 'externalControlPanels', label: i18n.t('constants.externalControlPanels') },
	wizards: { id: 'wizards', label: i18n.t('constants.wizards') },
});

export const QUERY_STRINGS = Object.freeze({
	sensorId: { id: 'sensor' },
	alarm: { date: 'alarmDate', value: 'alarmValue' },
});

export const GRAPH_TYPES = Object.freeze({ bar: 1, line: 2 });
export const GRAPH_INTERVAL_TYPES = Object.seal({ current: i18n.t('constants.currentPeriod'), former: i18n.t('constants.formerPeriod') });

// intervals : { barChart: number, lineChart: number }
function _getIntervalHours(intervals) {
	return { get: type => (type === GRAPH_TYPES.bar ? intervals.barChart : intervals.lineChart), ...intervals };
}
export const INTERVAL_VALUES = {
	day: {
		label: i18n.t('constants.day'),
		days: 1,
		interval: '1 hour',
		intervalHours: _getIntervalHours({ barChart: 1, lineChart: 1 }),
		chartTickCount: 13,
		dateFormat: 'HH:mm',
	},
	week: {
		label: i18n.t('constants.week'),
		days: 7,
		interval: '1 day',
		intervalHours: _getIntervalHours({ barChart: 24, lineChart: 24 / 4 }),
		chartTickCount: 7,
		dateFormat: 'd MMM',
	},
	month: {
		label: i18n.t('constants.month'),
		interval: '1 day',
		days: 30,
		intervalHours: _getIntervalHours({ barChart: 24, lineChart: 24 }),
		chartTickCount: 15,
		dateFormat: 'd MMM',
	},
	year: {
		label: i18n.t('constants.year'),
		interval: '1 month',
		days: 365,
		intervalHours: _getIntervalHours({ barChart: 24 * 30, lineChart: (24 * 30) / 2 }),
		chartTickCount: 12,
		dateFormat: 'MMM yyyy',
	},
	fiveYears: {
		label: i18n.t('constants.fiveYears'),
		interval: '1 year',
		days: 365 * 5,
		intervalHours: _getIntervalHours({ barChart: 24 * 30 * 6, lineChart: (24 * 30 * 6) / 2 }),
		chartTickCount: 10,
		dateFormat: 'yyyy',
	},
};

export const CLASSIFICATIONS = Object.seal({
	temperature: {
		id: 'temperature',
		label: i18n.t('sensorAdmin.temperature'),
		subcategories: { 
			indoor: { id: 'indoor', label: i18n.t('sensorAdmin.indoor'), units: { celsius: '°C', fahrenheit: '°F', kelvin: 'K' } },
			outdoor: { id: 'outdoor', label: i18n.t('sensorAdmin.outdoor'), units: { celsius: '°C', fahrenheit: '°F', kelvin: 'K' } },
			supply_air: { id: 'supply_air', label: i18n.t('sensorAdmin.supply_air'), units: { celsius: '°C', fahrenheit: '°F', kelvin: 'K' } },
			exhaust_air: { id: 'exhaust_air', label: i18n.t('sensorAdmin.exhaust_air'), units: { celsius: '°C', fahrenheit: '°F', kelvin: 'K' } },
			supply_water: { id: 'supply_water', label: i18n.t('sensorAdmin.supply_water'), units: { celsius: '°C', fahrenheit: '°F', kelvin: 'K' } },
			return_water: { id: 'return_water', label: i18n.t('sensorAdmin.return_water'), units: { celsius: '°C', fahrenheit: '°F', kelvin: 'K' } },
		},
		askForSetPoint: true,
		askForMultiplier: true,
		askForTotalInclusion: false,
		askForAccumulatedData: false,
	},
	humidity: {
		id: 'humidity',
		label: i18n.t('sensorAdmin.humidity'),
		subcategories: {
			relative: { id: 'relative', label: i18n.t('sensorAdmin.relative'), units: { percentRH: '%RH' } },
			absolute: { id: 'absolute', label: i18n.t('sensorAdmin.absolute'), units: { percent: '%' } },
		},
		askForSetPoint: true,
		askForMultiplier: true,
		askForTotalInclusion: false,
		askForAccumulatedData: false,
	},
	energy: {
		id: 'energy',
		label: i18n.t('sensorAdmin.energy'),
		subcategories: {
			electricity_usage: { id: 'electricity_usage', label: i18n.t('sensorAdmin.electricity_usage'), units: { kW: 'kW', "kWh": "kWh", "kW/m³/s": "kW/m³/s" } },
			district_heating_usage: { id: 'district_heating_usage', label: i18n.t('sensorAdmin.district_heating_usage'), units: { kW: 'kW', "kWh": "kWh" } },
			general_energy_usage: { id: 'general_energy_usage', label: i18n.t('sensorAdmin.general_energy_usage'), units: { kW: 'kW', "kWh": "kWh" } },
			electricity_production: { id: 'electricity_production', label: i18n.t('sensorAdmin.electricity_production'), units: { kW: 'kW', "kWh": "kWh" } },
		},
		askForSetPoint: false,
		askForMultiplier: true,
		askForTotalInclusion: true,
		askForAccumulatedData: true,
	},
	flow: {
		id: 'flow',
		label: i18n.t('sensorAdmin.flow'),
		subcategories: {
			water: { id: 'water', label: i18n.t('sensorAdmin.water'), units: { lpm: 'lpm', lps: 'l/s', m3h: 'm³/h' } },
			air: { id: 'air', label: i18n.t('sensorAdmin.air'), units: { lpm: 'l/m', lps: 'l/s', m3h: 'm³/h' } },
		},
		askForSetPoint: true,
		askForMultiplier: true,
		askForTotalInclusion: true,
		askForAccumulatedData: true,
	},
	pressure: {
		id: 'pressure',
		label: i18n.t('sensorAdmin.pressure'),
		subcategories: {
			air_supply_pressure: { id: 'air_supply_pressure', label: i18n.t('sensorAdmin.air_supply_pressure'), units: { Pa: 'Pa', bar: 'bar' } },
			air_exhaust_pressure: { id: 'air_exhaust_pressure', label: i18n.t('sensorAdmin.air_exhaust_pressure'), units: { Pa: 'Pa', bar: 'bar' } },
			water_supply_pressure: { id: 'water_supply_pressure', label: i18n.t('sensorAdmin.water_supply_pressure'), units: { Pa: 'Pa', bar: 'bar' } },
		},
		askForSetPoint: true,
	},
	water_and_waste: {
		id: 'water_and_waste',
		label: i18n.t('sensorAdmin.water_and_waste'),
		subcategories: {
			waste_weight: { id: 'waste_weight', label: i18n.t('sensorAdmin.waste'), units: { kg: 'kg' } },
			hot_water: { id: 'hot_water', label: i18n.t('sensorAdmin.hot_water'), units: { l: 'l' } },
			cold_water: { id: 'cold_water', label: i18n.t('sensorAdmin.cold_water'), units: { l: 'l' } },
		},
		askForSetPoint: false,
		askForMultiplier: true,
		askForTotalInclusion: false,
		askForAccumulatedData: true,
	},
	alarm: {
		id: 'alarm',
		label: i18n.t('sensorAdmin.alarm'),
		subcategories: {
			alarm: { id: 'alarm', label: i18n.t('sensorAdmin.alarm'), units: { bool: 'bool' } },
		},
		askForSetPoint: false,
		askForMultiplier: false,
		askForTotalInclusion: false,
		askForAccumulatedData: false,
	},
	status: {
		id: 'status',
		label: i18n.t('sensorAdmin.status'),
		subcategories: {
			onoff: { id: 'onoff', label: i18n.t('sensorAdmin.onoff'), units: { bool: 'boolean' } },
			percentage: { id: 'percentage', label: i18n.t('sensorAdmin.percentage'), units: { percent: '%' } },
		},
		askForSetPoint: true,
		askForMultiplier: true,
		askForTotalInclusion: false,
		askForAccumulatedData: false,
	},
	light_and_sound: {
		id: 'light_and_sound',
		label: i18n.t('sensorAdmin.light_and_sound'),
		subcategories: {
			sound: { id: 'sound', label: i18n.t('sensorAdmin.sound'), units: { dB: 'dB' } },
			light: { id: 'light', label: i18n.t('sensorAdmin.light'), units: { lux: 'lux' } },
		},
		askForSetPoint: false,
		askForMultiplier: true,
		askForTotalInclusion: false,
		askForAccumulatedData: false,
	},
	fanspeed: {
		id: 'fanspeed',
		label: i18n.t('sensorAdmin.fanspeed'),
		subcategories: {
			speed: { id: 'speed', label: i18n.t('sensorAdmin.speed'), units: { rpm: 'rpm', Hz: 'Hz' } },
		},
		askForSetPoint: false,
		askForMultiplier: true,
		askForTotalInclusion: false,
		askForAccumulatedData: false,
	},
	custom: {
		id: 'custom',
		label: i18n.t('sensorAdmin.custom'),
		subcategories: {
			custom: { id: 'custom', label: i18n.t('sensorAdmin.custom'), units: { userDefined: '' } },
		},
		askForSetPoint: true,
		askForMultiplier: true,
		askForTotalInclusion: false,
		askForAccumulatedData: true,
	},
});



// Prices are measured in SEK
export const UNIT_CLASSIFICATIONS = Object.seal({
	electricity: {
		id: 'electricity',
		label: i18n.t('constants.electricity'),
		unit: 'kWh',
		energyIndex: 0,
		priceMultiplier: 0.4
	},
	water: {
		id: 'water',
		label: i18n.t('constants.water'),
		unit: 'm³',
		energyIndex: 1,
		priceMultiplier: 0.3
	},
	districtHeating: {
		id: 'districtHeating',
		label: i18n.t('constants.districtHeating'),
		unit: 'kWh',
		energyIndex: 2,
		priceMultiplier: 0.14,
	},
	indoorTemperature: {
		id: 'indoorTemperature',
		label: i18n.t('constants.indoorTemperature'),
		unit: '°C',
		energyIndex: 0,
		priceMultiplier: 0
	},
	outdoorTemperature: {
		id: 'outdoorTemperature',
		label: i18n.t('constants.outdoorTemperature'),
		unit: '°C',
		energyIndex: 0,
		priceMultiplier: 0
	},
	energyConsumption: {
		id: 'energyConsumption',
		label: i18n.t('constants.energyConsumption'),
		unit: 'kWh'
	},
	other: {
		id: 'other',
		label: i18n.t('constants.other'),
		priceMultiplier: 1
	},
});

export const FILE_CLASSIFICATIONS = Object.seal({
	manual: { id: 'manual', label: i18n.t('constants.manual') },
	photo: { id: 'photo', label: i18n.t('constants.photo') },
	opcard: { id: 'operationcard', label: i18n.t('constants.operationCard') },
	other: { id: 'other', label: i18n.t('constants.other') },
});

export const THRESHOLD_TYPE = Object.freeze({ warning: 0, alarm: 1 }); // Enum-like object

export const PROP_SELECTION_TYPES = Object.freeze({ selected: { id: 'selected' }, parents: { id: 'parents' }, all: { id: 'all' } });

export const CO_CLASSIFICATIONS = Object.seal({
	setpoint: { value: 'setpoint', label: i18n.t('constants.setPoint') },
	curve: {
		value: 'curve',
		label: i18n.t('constants.curve'),
		subClasses: { x: { value: 'x', label: 'X' }, y: { value: 'y', label: 'Y' } }, // TODO: are X and Y universal?
	},
});

const setSensorSubTarget = {
	name: { id: 'name', label: i18n.t('generic.name') },
	locationId: { id: 'locationid', label: i18n.t('generic.property') },
	unit: { id: 'unit', label: i18n.t('generic.unit') },
	classification: { id: 'classification', label: i18n.t('constants.classification') },
	subcategory: { id: 'subcategory', label: i18n.t('constants.subcategory') },
	lowerThreshold: { id: 'lowerthreshold', label: i18n.t('constants.lowerThreshhold') },
	upperThreshold: { id: 'upperthreshold', label: i18n.t('constants.upperThreshhold') },
	sensorGroupId: { id: 'sensorgroupid', label: i18n.t('constants.sensorGroup') },
	multiplier: { id: 'multiplier', label: i18n.t('generic.multiplier') },
	includeintotal: { id: 'includeintotal', label: i18n.t('constants.includeInTotal') },
	accumulateddata: { id: 'accumulateddata', label: i18n.t('constants.accumulatedData') },
	alarmGraceMinutes: { id: 'alarmgraceminutes', label: i18n.t('constants.alarmDelayMinutes') },
	digitalTwinTagId: { id: 'digitaltwintagid', label: i18n.t('constants.flyInInfo') },
	digitalTwinPerspectiveId: { id: 'digitaltwinperspectiveid', label: i18n.t('constants.flyInCameraPos') },
	alarmPeriodIds: { id: 'alarmperiodids', label: i18n.t('constants.alarmPeriods') },
	setpoint: { id: 'setpoint', label: i18n.t('constants.setPoint') },
};
const setSensorGroupSubTarget = {
	name: { id: 'name', label: i18n.t('generic.name') },
	locationId: { id: 'locationid', label: i18n.t('generic.property') },
	digitalTwinTagId: { id: 'digitaltwintagid', label: i18n.t('constants.flyInInfo') },
	digitalTwinPerspectiveId: { id: 'digitaltwinperspectiveid', label: i18n.t('constants.flyInCameraPos') },
};
const setSensorLocationSubTarget = {
	city: { id: 'city', label: i18n.t('generic.city') },
	street: { id: 'street', label: i18n.t('generic.address') },
	cadastral: { id: 'cadastral', label: i18n.t('constants.cadastral') },
	area: { id: 'area', label: i18n.t('constants.squareMetres') },
	latitude: { id: 'latitude', label: i18n.t('constants.latitude') },
	longitude: { id: 'longitude', label: i18n.t('constants.longitude') },
};
const setFileRecordSubTarget = {
	displayName: { id: 'displayname', label: i18n.t('generic.name') },
};
const setDigitalTwinSubTarget = {
	model: { id: 'model', label: i18n.t('generic.id') },
	locationId: { id: 'locationid', label: i18n.t('generic.property') },
	label: { id: 'label', label: i18n.t('generic.name') },
};
const setDigitalTwinTagSubTarget = {
	digitalTwinId: { id: 'digitaltwinid', label: i18n.t('constants.digitalTwin') },
	label: { id: 'label', label: i18n.t('constants.title') },
	description: { id: 'description', label: i18n.t('generic.description') },
	mediaURL: { id: 'mediaurl', label: i18n.t('constants.mediaLink') },
	sensorIds: { id: 'sensorids', label: i18n.t('constants.sensors') },
	sensorGroupIds: { id: 'sensorgroupids', label: i18n.t('constants.sensorGroups') },
	positionX: { id: 'positionx', label: i18n.t('constants.xPos') },
	positionY: { id: 'positiony', label: i18n.t('constants.yPos') },
	positionZ: { id: 'positionz', label: i18n.t('constants.zPos') },
	normalX: { id: 'normalx', label: i18n.t('constants.xAngle') },
	normalY: { id: 'normaly', label: i18n.t('constants.yAngle') },
	normalZ: { id: 'normalz', label: i18n.t('constants.zAngle') },
};
const setCoSubTarget = {
	name: { id: 'name', label: i18n.t('generic.name') },
	locationId: { id: 'locationid', label: i18n.t('generic.property') },
	coAddress: { id: 'coaddress', label: i18n.t('generic.address') },
};
const setCoRegisterSubTarget = {
	coId: { id: 'coid', label: i18n.t('constants.cos') },
	name: { id: 'name', label: i18n.t('generic.name') },
	unit: { id: 'unit', label: i18n.t('generic.unit') },
	classification: { id: 'classification', label: i18n.t('constants.classification') },
	multiplier: { id: 'multiplier', label: i18n.t('constants.multiplier') },
};
const SetDeviationSubTarget = {
	locationId: { id: 'locationid', label: i18n.t('generic.property') },
	title: { id: 'title', label: i18n.t('generic.property') },
	description: { id: 'description', label: i18n.t('generic.description') },
	confirmComment: { id: 'confirmcomment', label: i18n.t('generic.comment') },
	digitalTwinTagId: { id: 'digitaltwintagid', label: i18n.t('constants.flyInInfo') },
	digitalTwinPerspectiveId: { id: 'digitaltwinperspectiveid', label: i18n.t('constants.flyInCameraPos') },
};
const SetConfirmSubTarget = {
	comment: { id: 'comment', label: i18n.t('generic.comment') },
};

export const PROPERTYACCESS_IDS = Object.freeze({ all: 'all', parents: 'parents' });
export const USERACCESS_IDS = Object.freeze({ all: 'all' });
export const AUTHORIZATIONACCESS_IDS = Object.freeze({
	all: 'all',
	parents: 'parents',
	selected: 'selected',
	read: 'read',
	minimum: 'minimum',
});
export const AUTHORIZATION_OPTIONS = {
	delete: { id: 'DELETE', name: i18n.t('constants.deleting'), description: i18n.t('constants.deletingDesc') },
	confirm: { id: 'CONFIRM', name: i18n.t('constants.receipt'), description: i18n.t('constants.receiptDesc') },
	editAlarmThreshold: {
		id: 'EDIT_ALARM_THRESHOLD',
		name: i18n.t('constants.editAlarmThreshhold'),
		description: i18n.t('constants.editAlarmThreshholdDesc'),
	},
};
export const REPORT_OPTIONS = {
	drawOverviewPage: { id: 'DRAW_OVERVIEW_PAGE', name: i18n.t('constants.overview') },
	//drawEnergyDataPage: { id: 'DRAW_ENERGY_DATA_PAGE', name: i18n.t('constants.perProptertyConsumption') },
	drawElectricityComparisonPage: { id: 'DRAW_ELECTRICITY_COMPARISON_PAGE', name: i18n.t('constants.electricityConsumptionComp') },
	drawWaterComparisonPage: { id: 'DRAW_WATER_COMPARISON_PAGE', name: i18n.t('constants.waterConsumptionComp') },
	drawDistrictHeatingComparisonPage: {
		id: 'DRAW_DISTRICT_HEATING_COMPARISON_PAGE',
		name: i18n.t('constants.districtHeatingConsumptionComp'),
	},
	drawChartPage: { id: 'DRAW_CHART_PAGE', name: i18n.t('constants.includeCharts') },
	drawAlarmPage: { id: 'DRAW_ALARM_PAGE', name: i18n.t('constants.includeAlarms') },
};

export const REPORT_INTERVALS = Object.freeze({
	day: { id: 'day', label: i18n.t('constants.day') },
	week: { id: 'week', label: i18n.t('constants.week') },
	month: { id: 'month', label: i18n.t('constants.month') },
	year: { id: 'year', label: i18n.t('constants.year') },
});

export const QUICK_REPORT_INTERVALS = Object.freeze({
	day: { id: 'day', label: i18n.t('constants.day') },
	week: { id: 'week', label: i18n.t('constants.week') },
	month: { id: 'month', label: i18n.t('constants.month') },
});

export const ACTION_TYPES = Object.seal({
	setSensor: { id: 'setSensor', subTargets: setSensorSubTarget, label: i18n.t('constants.sensorEdited') },
	setSensorGroup: { id: 'setSensorGroup', subTargets: setSensorGroupSubTarget, label: i18n.t('constants.sensorGroupEdited') },
	setSensorLocation: { id: 'setSensorLocation', subTargets: setSensorLocationSubTarget, label: i18n.t('constants.propertyEdited') },
	setFileRecord: { id: 'setFileRecord', subTargets: setFileRecordSubTarget, label: i18n.t('constants.fileEdited') },
	setDigitalTwin: { id: 'setDigitalTwin', subTargets: setDigitalTwinSubTarget, label: i18n.t('constants.digitalTwinEdited') },
	setDigitalTwinTag: { id: 'setDigitalTwinTag', subTargets: setDigitalTwinTagSubTarget, label: i18n.t('constants.infoPointEdited') },
	setCo: { id: 'setCo', subTargets: setCoSubTarget, label: i18n.t('constants.coEdited') },
	setCoRegister: { id: 'setCoRegister', subTargets: setCoRegisterSubTarget, label: i18n.t('constants.coRegisterEdited') },
	setDeviation: { id: 'setDeviation', subTargets: SetDeviationSubTarget, label: i18n.t('constants.deviationEdited') },
	setConfirm: { id: 'setConfirm', subTargets: SetConfirmSubTarget, label: i18n.t('constants.receiptEdited') },
	addSensorGroup: { id: 'addSensorGroup', label: i18n.t('constants.sensorGroupAdded') },
	addSensorLocation: { id: 'addSensorLocation', label: i18n.t('constants.propertyAdded') },
	addFileRecord: { id: 'addFileRecord', label: i18n.t('constants.fileAdded') },
	addDigitalTwin: { id: 'addDigitalTwin', label: i18n.t('constants.digitalTwinAdded') },
	addDigitalTwinTag: { id: 'addDigitalTwinTag', label: i18n.t('constants.infoPointAdded') },
	addDigitalTwinPerspective: { id: 'addDigitalTwinPerspective', label: i18n.t('constants.cameraPosAdded') },
	addCo: { id: 'addCo', label: i18n.t('constants.coAdded') },
	addDeviation: { id: 'addDeviation', label: i18n.t('constants.deviationAdded') },
	removeFileRecord: { id: 'removeFileRecord', label: i18n.t('constants.fileDeleted') },
	removeDigitalTwinTag: { id: 'removeDigitalTwinTag', label: i18n.t('constants.infoPointDeleted') },
});

export const CHART_WIDGET_TYPES = createEnum(['LineChart', 'Table', 'BarChart', 'PieChart', 'SpeedoMeter']);

export const MINUTES_PER_HOUR = 60;
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * 24;
export const MINUTES_PER_WEEK = MINUTES_PER_DAY * 7;
export const MINUTES_PER_MONTH = MINUTES_PER_DAY * 30;
export const MINUTES_PER_YEAR = MINUTES_PER_DAY * 365;
export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;

export const CHART_TIME_SELECTION_INTERVALS = [
	{ value: MINUTES_PER_DAY, text: i18n.t('timeSelect.day') },
	{ value: MINUTES_PER_WEEK, text: i18n.t('timeSelect.week') },
	{ value: MINUTES_PER_MONTH, text: i18n.t('timeSelect.month') },
	{ value: MINUTES_PER_YEAR, text: i18n.t('timeSelect.year') },
];

export const CHART_AUTO_UPDATE_INTERVALS = [
	{ interval: 5 * SECONDS_PER_MINUTE, text: i18n.t('autoUpdate.minutes5') },
	{ interval: 10 * SECONDS_PER_MINUTE, text: i18n.t('autoUpdate.minutes10') },
	{ interval: 15 * SECONDS_PER_MINUTE, text: i18n.t('autoUpdate.minutes15') },
	{ interval: 30 * SECONDS_PER_MINUTE, text: i18n.t('autoUpdate.minutes30') },
	{ interval: 1 * SECONDS_PER_HOUR, text: i18n.t('autoUpdate.hours1') },
	{ interval: 2 * SECONDS_PER_HOUR, text: i18n.t('autoUpdate.hours2') },
	{ interval: 4 * SECONDS_PER_HOUR, text: i18n.t('autoUpdate.hours4') },
	{ interval: 24 * SECONDS_PER_HOUR, text: i18n.t('autoUpdate.hours24') },
];

export const LINKS_PATHNAME = '/links';

export const VERSION = require('./version.js');
