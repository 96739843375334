import { UNIT_CLASSIFICATIONS } from '../constants';

/**
 * Returns a filtered list of energy-sensors
 * @param {object[]} sensors : Sensors to filter
 * @param {sensor => boolean} rule : Returns true if the sensor should be included
 * @returns {object[]} : `sensors` - minus non-energy-sensors
 */
function getEnergySensors(sensors, rule) {
	return (
		sensors?.filter(
			sen =>
				[UNIT_CLASSIFICATIONS.electricity.id, UNIT_CLASSIFICATIONS.districtHeating.id].includes(
					sen.classification
				) &&
				(typeof rule !== 'function' || rule(sen))
		) || []
	);
}

export { getEnergySensors };
